<template>
  <footer id="footer" class="section-footer">
    <div class="footer">
      <div class="footer-top">
        <div class="container-custom">
          <div class="footer-top__content">
            <nuxt-link to="/" class="logo">
              <BaseImg src="/assets/images/logo.svg" alt="logo" @click="goHome" />
            </nuxt-link>
            <p class="slogan-description">
              {{ FOOTER_CONTENT?.SLOGAN_DESCRIPTION }}
              <a class="view-more" :href="PAGE_URLS.INTRODUCTION">{{ FOOTER_CONTENT?.VIEW_MORE }}</a>
            </p>
          </div>
        </div>
      </div>
      <div class="footer-navigation">
        <div class="container-custom">
          <div class="footer-navigation__content">
            <div v-for="item in NAVIGATIONS" :key="item.title" class="footer-section">
              <p class="section-title">
                {{ item.title }}
              </p>
              <div v-if="item.subCategories.length <= 5">
                <a
                  v-for="category in item.subCategories"
                  :id="category.title"
                  :key="category.title"
                  class="category-name"
                  :href="category.link"
                  :target="category?.type === 'BLANK' ? '_blank' : '_self'"
                >
                  {{ category.title }}
                </a>
                <nuxt-link
                  v-if="item.subCategories.length < 2 && $config.SHOW_NEWS !== '0'"
                  class="category-name"
                  :to="PAGE_URLS.NEWS"
                  @click="openLink(PAGE_URLS.NEWS)"
                >
                  Tin tức
                </nuxt-link>
              </div>
              <div v-else>
                <div class="row-footer">
                  <div class="column">
                    <a
                      v-for="category in item.subCategories.slice(0, 5)"
                      :id="category.title"
                      :key="category.title"
                      class="category-name"
                      :href="category.link"
                    >
                      {{ category.title }}
                    </a>
                  </div>
                  <div class="column-right">
                    <a
                      v-for="category in item.subCategories.slice(5)"
                      :id="category.title"
                      :key="category.title"
                      class="category-name"
                      :href="category.link"
                    >
                      {{ category.title }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-deposit">
        <div class="container-custom">
          <div class="footer-deposit__content">
            <nuxt-link
              v-for="item in DEPOSIT_LIST"
              :id="item.title"
              :key="item.title"
              :to="item?.link || '/#'"
              class="deposit-item"
              @click="handleClickMethod(item)"
            >
              <BaseImg class="deposit-image" :src="item.image" :alt="item?.title" />
              <p class="deposit-title">{{ item.title }}</p>
            </nuxt-link>
          </div>
        </div>
      </div>
      <div class="footer-reserve">
        <div class="container-custom">
          <div class="footer-reserve__content">
            <div class="footer-reserve__left">
              <nuxt-link
                title="DMCA.com Protection Status"
                target="_blank"
                to="//www.dmca.com/Protection/Status.aspx?ID=f362511c-0d21-4b76-af37-f633d9a377ba"
              >
                <BaseImg src="assets/images/components/desktop/footer/dmca.svg" class="dmca" alt="copy right" />
              </nuxt-link>
              <p class="reserve-text">{{ FOOTER_CONTENT.COPY_RIGHT }}</p>
            </div>

            <BaseImg
              src="assets/images/components/desktop/footer/copyright-logo.png"
              class="reserve-image"
              alt="copy right"
            />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import useModal from '~/composables/useModal'
import { useAppStore } from '~~/store/app'
import { PAGE_URLS } from '~/config/page-url'
import { getFooterNavigation, DEPOSIT_LIST, footerContentData } from '@/constants/footer'
import BaseImg from '~/components/common/base-img'
import { MODAL_TYPES } from '~/config/constant'
const { openModalWithNavigate } = useModal()
const { NAVIGATIONS } = getFooterNavigation()
const router = useRouter()
const { $config, $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser } = storeToRefs(store)
const FOOTER_CONTENT = footerContentData()
const handleClickMethod = (item: any) => {
  if (currentUser.value === null) {
    openModalWithNavigate(MODAL_TYPES.LOGIN, 'login', item?.link)
  } else {
    navigateTo(item?.link)
  }
}

const openLink = (url: string) => {
  switch (url) {
    case 'LIVECHAT':
      // TODO
      break
    case 'TELEGRAM_CSKH':
      window.open(useRuntimeConfig().TELEGRAM_CSKH, '_blank')
      break
  }
}

const goHome = () => {
  router.push({ path: PAGE_URLS.HOME })
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/footer/index.scss" />
